import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';
import getLocalType from 'utils/getLocalType';
import { RequestError } from 'utils/errors/RequestError';

import {
  forEach, includes,
} from 'lodash';

import config from 'config';

export const initialState = {
  data: [],
  floorMap: {},
};

export const actionTypes = {
  LOAD_DATA_INIT: 'LOCAL/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'LOCAL/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'LOCAL/LOAD_DATA_ERROR',
  SELECT_FLOOR_ID: 'LOCAL/SELECT_FLOOR_ID',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0].nodeById;
      const svgData = action.data[1];
      const investment = data && data.building ? data.building.entity.investment.entity : null;

      const local = {
        ...data,
        price: parseFloat(data.price),
        priceM2: parseFloat(data.priceM2),
        pricePromo: parseFloat(data.pricePromo),
        priceM2Promo: parseFloat(data.priceM2Promo),
        promoLowestPrice: parseFloat(data.promoLowestPrice),
        promoFromDate: GraphQLTransforms.timestamp(
          data.promoFromDate && data.promoFromDate.value,
        ),
        promoToDate: GraphQLTransforms.timestamp(data.promoToDate && data.promoToDate.value),
        surface: parseFloat(data.surface),
        type: getLocalType(data.type.entity.id),
        typeId: data.type.entity.id,
        status: GraphQLTransforms.name(data.status),
        direction: data.direction.map(GraphQLTransforms.name),
        imageUrl: GraphQLTransforms.image(data.imageUrl),
        image: data.imageUrl,
        roomsList: data.roomsList.map((el) => ({
          ...el.entity,
          name: GraphQLTransforms.value(el.entity.name),
        })),
        cardUrl: `${config.apiHost}pdf/local/${data.id}`,
        visualisationUrl: GraphQLTransforms.imageStyle(data.visualisationUrl),
        saleWithGarageOnly: investment.saleWithGarageOnly,
        investment: {
          ...investment,
          logo: GraphQLTransforms.imageStyle(investment.logo),
          image: GraphQLTransforms.imageStyle(investment.image),
        },
      };

      const features = {};

      forEach(local.roomsList, (room) => {
        const isFeature = includes(
          [
            config.search.featureIds.balcony,
            config.search.featureIds.balconyBuiltUp,
            config.search.featureIds.loggia,
            config.search.featureIds.loggiaClosed,
            config.search.featureIds.garden,
            config.search.featureIds.terrace,
            config.search.featureIds.terraceGreen,
          ],
          room.name,
        );

        if (isFeature) {
          features[room.name] = true;
        }
      });

      local.features = Object.keys(features);

      return {
        ...state,
        data: local,
        floorMap: {
          svgData,
          selectedFloorId: String(local.floor),
        },
      };
    }

    case actionTypes.SELECT_FLOOR_ID:
      return {
        ...state,
        floorMap: {
          ...state.floorMap,
          selectedFloorId: action.data,
        },
      };

    default:
      return state;
  }
};

export const changeSelectedFloor = (data) => ({
  type: actionTypes.SELECT_FLOOR_ID,
  data,
});

const loadDataInit = () => ({
  type: actionTypes.LOAD_DATA_INIT,
});

const loadDataSuccess = (data) => ({
  type: actionTypes.LOAD_DATA_SUCCESS,
  data,
});

const loadDataError = (data) => ({
  type: actionTypes.LOAD_DATA_ERROR,
  data,
});

export const loadData = (localSlug) => async (dispatch, getStore) => {
  const actions = {
    onInit: loadDataInit,
    onSuccess: loadDataSuccess,
    onError: loadDataError,
  };

  const store = getStore();

  const mappedLocal = store.Shared.mappedLocalsSlugs[localSlug];
  const mappedBuilding = store.Shared.mappedLocalsSlugs[localSlug];

  const localId = mappedLocal && mappedLocal.id ? mappedLocal.id.toString() : localSlug;
  const buildingId = mappedBuilding && mappedBuilding.buildingId ? mappedBuilding.buildingId.toString() : localSlug;

  const localRequest = {
    type: 'graphql',
    query: `
      query getInvestment($id: String!) {
        nodeById(id: $id) {
          ... on NodeLocal {
            id: nid,
            title,
            rooms: fieldLocalRooms,
            status: fieldLocalStatus {
              entity {
                id: tid,
                name
              }
            },
            surface: fieldLocalSurface,
            floor: fieldLocalFloor,
            direction: fieldLocalWorldDirection {
              entity {
                id: tid,
                name
              }
            },
            features: fieldLocalFeatures {
              entity {
                id: tid,
                name,
              }
            },
            price: fieldLocalTotalPrice,
            priceM2: fieldLocalPriceM2,
            pricePromo: fieldLocalTotalPricePromo,
            priceM2Promo: fieldLocalPriceM2Promo,
            promoType: fieldLocalPromoType,
            promoLowestPrice: fieldLowestPromoPrice,
            promoFromDate: fieldLocalPromoTo { 
              value
            }
            promoToDate: fieldLocalPromoFrom {
              value
            }
            imageUrl: fieldLocal2d {
              width,
              height,
              entity {
                url
              }
            },
            roomsList: fieldLocalRoomsList {
              entity {
                ... on ParagraphRoom {
                  id,
                  name: fieldRoomName {
                    value
                  },
                  surface: fieldRoomSurface,                  
                }
              }
            },
            type: fieldLocalType {
              entity {
                id: tid,
                name,
              }
            },
            visualisationUrl: fieldLocal3d {
              derivative(style: MAKIETA3D) {
                url,
              }
            }
            sliderImages: fieldLocalSliderImages {
              imageUrl: url
            }
            building: fieldLocalBuilding {
              entity {
                ... on NodeBuilding {
                  nid
                  buildingNumber: fieldBuildingNumber
                  investment: fieldBuildingInvestment {
                    entity {
                      ... on NodeInvestment {
                        id: nid,
                        slug: fieldInvestmentSlug
                        title,
                        realizationDateText: fieldRealizationDate,
                        logo: fieldInvestmentLogo {
                          derivative(style: MEDIUM) {
                            url,
                          }
                        }
                        image: fieldInvestmentBreadcrumbImg {
                          derivative(style: MAINPAGESLIDER) {
                            url,
                          }
                        },
                        saleWithGarageOnly: fieldSaleWithGarageOnly
                        newLook: fieldInvestmentNewLook
                        additionalNote: fieldInvestmentAdditionalNote
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: { id: localId },
  };

  const svgRequest = {
    method: 'GET',
    url: `svgConverter/floor/${buildingId}?attachWindRose`,
  };

  const svgPromise = ApiManager
    .request({
      dispatch: () => {},
      actions,
      requests: svgRequest,
    })
    .catch((error) => {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      return [null];
    });

  const localDataPromise = ApiManager
    .request({
      dispatch: () => {},
      actions,
      requests: localRequest,
    });

  const [svgData, localData] = await Promise.all([
    svgPromise,
    localDataPromise,
  ]);

  const data = [...localData, ...svgData].filter((response) => Boolean(response && response.id));

  dispatch(loadDataSuccess(data.map((response) => response && response.data)));
  return data;
};
