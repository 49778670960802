import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  items: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'NEWS_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'NEWS_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'NEWS_LIST/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0];

      return {
        ...state,
        slides: GraphQLTransforms.getNode(data, 'slides').map((el) => ({
          ...el,
          imageUrl: GraphQLTransforms.imageStyle(el.imageUrl),
        })),
        items: GraphQLTransforms.getNode(data, 'news').map((el) => ({
          ...el,
          investment: GraphQLTransforms.value(el.investment),
          timestamp: el.timestamp * 1000,
          header: el.title,
          summary: GraphQLTransforms.value(el.summary) || GraphQLTransforms.value(el.body),
          srcImage: GraphQLTransforms.imageStyle(el.srcImage),
        })),
      };
    }
    default:
      return state;
  }
};

export const loadData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      {
        slides: nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["slide"]},
              {field: "status", value: ["1"]},
              {field: "field_slide_type", value: ["news"]},
            ]
          },
        ) {
          entities {
            ... on NodeSlide {
              imageUrl: fieldSlideImage {
                derivative(style: MAINPAGESLIDER) {
                  url,
                }
              },
            }
          }
        }
        news: nodeQuery(limit:1000, filter: {conditions: [
            {field: "type", value: ["news"]},
            {field: "status", value: ["1"]}
        ]}, sort: [{
          field: "created"
          direction: DESC
        }]) {
          entities {
            ... on NodeNews {
              id: nid,
              slug: fieldNewsSlug,
              title,
              timestamp: created
              summary: fieldNewsDescription {
                value: summary
              },
              body: fieldNewsDescription {
                value
              },
              srcImage: fieldNewsMainPhoto {
                derivative(style: BLOGSMALL) {
                  url,
                }
              },
              investment: fieldNewsInvestment {
                value: entity {
                  id: nid,
                  title,
                }
              }               
            }
          }
        }
      }
    `,
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
