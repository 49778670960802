import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  data: {},
};

export const actionTypes = {
  LOAD_DATA_INIT: 'LEGAL_INFORMATION/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'LEGAL_INFORMATION/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'LEGAL_INFORMATION/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0].nodeById;
      return {
        ...state,
        data: {
          title: data.title,
          content: GraphQLTransforms.value(data.content),
        },
      };
    }
    default:
      return state;
  }
};

const loadDataInit = () => ({
  type: actionTypes.LOAD_DATA_INIT,
});

const loadDataSuccess = (data) => ({
  type: actionTypes.LOAD_DATA_SUCCESS,
  data,
});

const loadDataError = (data) => ({
  type: actionTypes.LOAD_DATA_ERROR,
  data,
});

export const loadData = (id) => (dispatch) => {
  const newId = id.toString();
  const actions = {
    onInit: loadDataInit,
    onSuccess: loadDataSuccess,
    onError: loadDataError,
  };

  const requests = [];

  requests.push({
    type: "graphql",
    query: `
      query getStaticData($id: String!) {
        nodeById(id: $id) {
          ... on NodeStatic {
            title,
            content: body {
              value
            }
          }
        }
      }
    `,
    variables: { id: newId },
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
