import ApiManager from 'utils/ApiManager';

export const initialState = {
  poiItems: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'POI/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'POI/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'POI/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const poiItems = [];

      action.data[0].nodeById.poi.forEach((el) => {
        poiItems.push({
          name: el.entity.map.name,
          lat: el.entity.map.lat,
          lng: el.entity.map.lng,
          type: el.entity.type,
        });
      });

      return {
        ...state,
        poiItems,
      };
    }
    default:
      return state;
  }
};

export const loadData = (investment) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      query getPoI($id: String!) {
        nodeById(id: $id) {
          ... on NodeInvestment {
            poi: fieldInvestmentPoi {
              entity {
                ... on ParagraphPoi {
                  type: fieldPoiType,
                  map: fieldPoiMap {
                    name,
                    lat,
                    lng: lon
                  }
                }
              }
            }           
          }
        }
      }
    `,
    variables: { id: investment },
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
